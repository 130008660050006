.container {
  margin: 2rem auto;
  padding: 1rem;
  width: 80%;
  border: 2px solid white;
  background: black;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
